
















































import { Observer } from 'mobx-vue'
import { Component, Inject, Vue } from 'vue-property-decorator'
import { InviteViewModel } from '../../../viewmodels/invites-viewmodel'

@Observer
@Component({
  components: {
    'invite-item': () => import('./invite-item.vue'),
  },
})
export default class extends Vue {
  @Inject() vm!: InviteViewModel
  tabs: string[] = ['All', 'Invitation', 'History']
  tab = this.tabs[0]

  onTabChange(tab: string) {
    this.tab = tab
  }
}
